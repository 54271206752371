import React from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import MUIAutocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from 'components/ui-components-v2/Chip';
import TextField from 'components/ui-components-v2/TextField';
import './styles/main.scss';

interface Props {
    className?: string;
    options: { icon?: React.ReactNode; value: string; label: string; disabled?: boolean; key?: string }[];
    value:
        | {
              icon?: React.ReactNode;
              value: string;
              label: string;
              disabled?: boolean;
              key?: string;
          }
        | {
              icon?: React.ReactNode;
              value: string;
              label: string;
              disabled?: boolean;
              key?: string;
          }[];
    isFont?: boolean;
    onChange: (value: Props['value']) => void;
    fullWidth?: boolean;
    size?: 'small' | 'medium';
    label?: string | JSX.Element;
    placeholder?: string;
    disabled?: boolean;
    InputProps?: any;
    multiple?: boolean;
    dataCyPrefix?: string;
}

const Autocomplete = ({
    className,
    options,
    value,
    isFont,
    onChange,
    fullWidth,
    size = 'small',
    label,
    placeholder,
    disabled,
    multiple,
    dataCyPrefix
}: Props) => {
    return (
        <MUIAutocomplete
            className={classNames('template-designer__autocomplete', className, {
                'template-designer__autocomplete--disabled': disabled
            })}
            classes={{
                inputRoot: 'template-designer__autocomplete__input-root',
                input: classNames(
                    'template-designer__autocomplete__input-autocomplete',
                    size === 'medium' && 'template-designer__autocomplete__input-autocomplete--medium'
                ),
                popupIndicator: 'template-designer__autocomplete__popup-indicator',
                endAdornment: 'template-designer__autocomplete__end-adornment',
                option: classNames('template-designer__autocomplete__option', size === 'medium' && 'template-designer__autocomplete__option--medium'),
                noOptions: 'template-designer__autocomplete__no-options'
            }}
            multiple={multiple}
            options={[{ value: '', label: '' }, ...options]}
            renderOption={(props, option) => {
                if (option.value === '' && option.label === '') return false;
                return (
                    <Box component="li" sx={{ '& > div': { mr: 1, flexShrink: 0 } }} data-cy={`templateDesigner-autoComplete-${option.key}-li`} {...props}>
                        {option.icon && <div className="template-designer__autocomplete__option__icon">{option.icon}</div>}
                        <span style={{ fontFamily: isFont ? option.value : '' }}>{option.label}</span>
                    </Box>
                );
            }}
            value={value}
            fullWidth={fullWidth}
            disabled={disabled}
            disableClearable
            isOptionEqualToValue={(option, value) => {
                if (value.value === null) {
                    return false;
                }
                return option.value === value.value;
            }}
            onChange={(_, newValue: Props['value'] | null) => {
                if (!newValue) return;
                onChange(newValue);
            }}
            renderTags={
                multiple
                    ? (value, getTagProps) =>
                          value.map((option, index) => (
                              <span key={option.value} className="template-designer__autocomplete__chip">
                                  <Chip
                                      variant="outlined"
                                      label={option.label}
                                      {...getTagProps({ index })}
                                      onDelete={
                                          option.disabled
                                              ? undefined
                                              : () => {
                                                    const newValue = [...value];
                                                    newValue.splice(index, 1);
                                                    onChange(newValue);
                                                }
                                      }
                                  />
                              </span>
                          ))
                    : undefined
            }
            renderInput={(props) => {
                if (!Array.isArray(value) && value.icon) {
                    // eslint-disable-next-line react/prop-types
                    props.InputProps.startAdornment = <InputAdornment position="start">{<div>{value.icon}</div>}</InputAdornment>;
                }

                return (
                    <TextField
                        {...props}
                        className={'template-designer__autocomplete__input'}
                        data-cy={`${dataCyPrefix}-text-input`}
                        variant="outlined"
                        size="small"
                        label={label}
                        placeholder={placeholder}
                        disabled={disabled}
                        fullWidth
                        InputLabelProps={{
                            classes: {
                                shrink: 'template-designer__autocomplete__input__input-label--shrink'
                            }
                        }}
                        InputProps={{
                            ...(props?.InputProps || []),
                            classes: {
                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                /* @ts-ignore */
                                ...(props?.InputProps?.classes || []),
                                root: 'template-designer__autocomplete__input__input-label'
                            }
                        }}
                    />
                );
            }}
        />
    );
};

export { Autocomplete };
